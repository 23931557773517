import { makeStyles, theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "120%"
    }
  },
  flexChild: { margin: theme.spacing(1), flexGrow: "1" },
  credentials: { flexBasis: "100%" },
  contracts: { flexBasis: "100%" },
  hotelDetails: { flexBasis: "100%" },
  groupContractDetails: { flexBasis: "100%" },
  accessGroupPanel: {
    maxHeight: "500px",
    overflow: "auto",
  },
  groupContractDetailsPanel: { flexBasis: "100%" },
  flexibleParkingAccountPanel: { flexBasis: "100%" }
}));
