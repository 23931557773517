import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
        display: "block",
    },
  },
  checkbox: {
    transform: "scale(1.5)",
    marginRight: theme.spacing(1),
  },
  payType: {
    flex: 1,
    flexBasis: "40%",
    marginBottom: "20px"
   },
  payTypeTmp: {
    flex: 1,
    flexBasis: "60%"
  },
  payContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
      display: "block",
    },
  },
  details: {
    width: "100%"
  }
}));
