import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./CreditCardOnFilePaymentMethodForm.styles";
import {
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import FormLabelCheckbox from "../../../../Checkboxes/FormLabelCheckbox/FormLabelCheckbox";

const CreditCardOnFilePaymentMethodForm = ({
  disabled,
  formControl,
  handleBlur
}) => {
  const classes = useStyles();

  return (
    <Grid item className={clsx("card-content", classes.cardOnFilePaymentContainer)} spacing={2}>
      <Grid container item xs={12} lg={5} spacing={2}>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="show-balance-checkbox-label"
            checkboxClassName="show-balance-checkbox"
            formControl={formControl}
            name="showBalance"
            labelTitle="Show balance on lane device screen"
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="email-receipts-checkbox-label"
            checkboxClassName="email-receipts-checkbox"
            formControl={formControl}
            name="emailReceipts"
            labelTitle="Email receipts"
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CreditCardOnFilePaymentMethodForm.propTypes = {
  disabled: PropTypes.bool,
  formControl: PropTypes.any,
  handleBlur: PropTypes.func,
};

export default CreditCardOnFilePaymentMethodForm;
