import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ChargedPaymentMethodForm.styles"
import {
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import FormLabelCheckbox from "../../../../Checkboxes/FormLabelCheckbox/FormLabelCheckbox";

const ChargedPaymentMethodForm = ({
  disabled,
  formControl,
  watch,
  resetField,
  errors,
  handleBlur
}) => {
  const classes = useStyles();

  const autoRechargeValue = watch("autoRecharge");
  const allowNegativeValue = watch("allowNegative");

  useEffect(() => {
    if (!autoRechargeValue) resetField("rechargeAmount", { defaultValue: "0.00" });
  }, [autoRechargeValue]);

  useEffect(() => {
    if (!allowNegativeValue) resetField("negativeLimit", { defaultValue: "0.00" });
  }, [allowNegativeValue]);

  return (
    <Grid item className={clsx("card-content", classes.chargedPaymentContainer)} spacing={2}>
      <Grid container item xs={12} lg={7} spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="threshold"
            control={formControl}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Low Threshold"
                {...field}
                error={!!errors.threshold}
                helperText={errors.threshold && errors.threshold.message}
                type="text"
                variant="outlined"
                disabled={disabled}
                className={clsx("threshold")}
                onBlur={handleBlur}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-threshold",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="auto-recharge-checkbox-label"
            checkboxClassName="auto-recharge-checkbox"
            formControl={formControl}
            name="autoRecharge"
            labelTitle= "Auto recharge when balance is below low threshold."
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="rechargeAmount"
            control={formControl}
            render={({ field }) => (
              <TextField
                disabled={!autoRechargeValue || disabled}
                fullWidth
                label="Recharge Amount"
                {...field}
                error={!!errors.rechargeAmount}
                helperText={
                  errors.rechargeAmount && errors.rechargeAmount.message
                }
                type="text"
                variant="outlined"
                onBlur={handleBlur}
                className={clsx("rechargeAmount")}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-rechargeamount",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={5} spacing={2}>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="show-fee-checkbox-label"
            checkboxClassName="show-fee-checkbox"
            formControl={formControl}
            name="showFee"
            labelTitle= "Show fee on lane device screen"
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="show-balance-checkbox-label"
            checkboxClassName="show-balance-checkbox"
            formControl={formControl}
            name="showBalance"
            labelTitle= "Show balance on lane device screen"
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabelCheckbox
            labelClassName="allow-negative-checkbox-label"
            checkboxClassName="allow-negative-checkbox"
            formControl={formControl}
            name="allowNegative"
            labelTitle= "Allow account to go negative"
            disabled={disabled}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="negativeLimit"
            control={formControl}
            render={({ field }) => (
              <TextField
                disabled={!allowNegativeValue || disabled}
                fullWidth
                label="Limit"
                {...field}
                error={!!errors.negativeLimit}
                helperText={
                  errors.negativeLimit && errors.negativeLimit?.message
                }
                type="text"
                variant="outlined"
                onBlur={handleBlur}
                className={clsx("negativeLimit")}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-negativelimit",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">-$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ChargedPaymentMethodForm.propTypes = {
  disabled: PropTypes.bool,
  formControl: PropTypes.any,
  watch: PropTypes.any,
  resetField: PropTypes.any,
  errors: PropTypes.any,
  handleBlur: PropTypes.func,
};

export default ChargedPaymentMethodForm;
