import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@material-ui/core";
import { useStyles } from "./style";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useCountry } from '../../../../hooks/useCountry';
import { COUNTRY_CODES } from '../../../../constants'

export const AddressSubformSchema = Yup.object()
    .shape({
        type: Yup.string()
            .oneOf(["Unset", "Home", "Work"])
            .default("Home")
            .notRequired(),
        addressLine1: Yup.string()
            .when(['addressLine2', 'countryID', 'city', 'subdivisionID', 'postalCode'], {
                is: (addressLine2, countryID, city, subdivisionID, postalCode) => (addressLine2 && addressLine2.length > 0) || (countryID && countryID.length > 0) || (city && city.length > 0) || (subdivisionID && subdivisionID.length >0) || (postalCode && postalCode.length > 0),
                then: Yup.string().required("Must be filled"),
                otherwise: Yup.string().trim('No leading or trailing spaces...').notRequired()
            }),
        addressLine2: Yup.string().trim('No leading or trailing spaces...').notRequired(),
        countryID: Yup.string()
            .when(['addressLine1', 'addressLine2', 'city', 'subdivisionID', 'postalCode'], {
                is: (addressLine1, addressLine2, city, subdivisionID, postalCode) => (addressLine1 && addressLine1.length > 0) || (addressLine2 && addressLine2.length > 0) || (city && city.length > 0) || (subdivisionID && subdivisionID.length > 0) || (postalCode && postalCode.length > 0),
                then: Yup.string().required('A country must be selected'),
                otherwise: Yup.string().notRequired()
            }),
        city: Yup.string()
            .when(['addressLine1', 'addressLine2', 'countryID', 'subdivisionID', 'postalCode'], {
                is: (addressLine1, addressLine2, countryID, subdivisionID, postalCode) => (addressLine1 && addressLine1.length > 0) || (addressLine2 && addressLine2.length > 0) || (countryID && countryID.length > 0) || (subdivisionID && subdivisionID.length > 0) || (postalCode && postalCode.length > 0),
                then: Yup.string().required("Must be filled"),
                otherwise: Yup.string().trim('No leading or trailing spaces...').notRequired()
            }),
        subdivisionID: Yup.string()
            .when(['addressLine1', 'addressLine2', 'countryID', 'city', 'postalCode'], {
                is: (addressLine1, addressLine2, countryID, city, postalCode) => (addressLine1 && addressLine1.length > 0) || (addressLine2 && addressLine2.length > 0) || (countryID && countryID.length > 0) || (city && city.length > 0) || (postalCode && postalCode.length > 0),
                then: Yup.string().required('A state/province must be selected'),
                otherwise: Yup.string().notRequired()
            }),
        postalCode: Yup.string()
            .when(['addressLine1', 'addressLine2', 'countryID', 'city', 'subdivisionID'], {
                is: (addressLine1, addressLine2, countryID, city, subdivisionID) => (addressLine1 && addressLine1.length > 0) || (addressLine2 && addressLine2.length > 0) || (countryID && countryID.length > 0) || (city && city.length > 0) || (subdivisionID && subdivisionID > 0),
                then: Yup.string().required("Must be filled"),
                otherwise: Yup.string().trim('No leading or trailing spaces...').notRequired()
            }),
        
        
    }, [['addressLine1', 'addressLine2'],
        ['addressLine1', 'countryID'],
        ['addressLine1', 'city'],
        ['addressLine1', 'subdivisionID'],
        ['addressLine1', 'postalCode'],
        ['addressLine2', 'countryID'],
        ['addressLine2', 'city'],
        ['addressLine2', 'subdivisionID'],
        ['addressLine2', 'postalCode'],
        ['countryID', 'city'],
        ['countryID', 'subdivisionID'],
        ['countryID', 'postalCode'],
        ['city', 'subdivisionID'],
        ['city', 'postalCode'],
        ['subdivisionID', 'postalCode'],
    ]);

    

const AddressSubform = ({
    index,
    disabled,
}) => {
    const classes = useStyles();
    const { control, setValue, trigger, formState: { errors } } = useFormContext();
    const { countries, subdivisions, selectedCountryId, setSelectedCountryId } = useCountry();
    const [subdivisionLabel, setSubdivisionLabel] = useState('State/Province');
    const formTriggerArray = [
        `contactInfo.addresses.${index}.addressLine1`,
        `contactInfo.addresses.${index}.addressLine2`,
        `contactInfo.addresses.${index}.countryID`,
        `contactInfo.addresses.${index}.city`,
        `contactInfo.addresses.${index}.subdivisionID`,
        `contactInfo.addresses.${index}.postalCode`
    ]

    const formatTextField = (e) => {
        const val = (e.target.value || "").replace(/\s+/gi, " ");
        setValue(e.target.name, val.trim());
        trigger(formTriggerArray);
    };

    const countryID = useWatch({
        name: `contactInfo.addresses.${index}.countryID`
    })

    useEffect(() => {
        console.log(countryID)
        if (countryID) {
            if (!selectedCountryId) {
                setSelectedCountryId(countryID);
            }

            if (selectedCountryId && selectedCountryId !== countryID) {
                setSelectedCountryId(countryID);
                setValue(`contactInfo.addresses.${index}.subdivisionID`, '');
                trigger(formTriggerArray);
            }

            if (countryID === COUNTRY_CODES.UnitedStates) {
                setSubdivisionLabel('State')
            } else if (countryID === COUNTRY_CODES.Canada) {
                setSubdivisionLabel('Province')
            }
        }
    }, [countryID])

    return (
        <>
            <Controller
                name={`contactInfo.addresses.${index}.addressLine1`}
                control={control}
                render={({ field }) => <TextField
                    {...field}
                    size="small"
                    className={clsx(
                        "addressline1",
                        classes?.addressLine1,
                        classes?.flexInput
                    )}
                    label="Address Line 1"
                    variant="outlined"
                    onBlur={formatTextField}
                    error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.addressLine1}
                    helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.addressLine1 && errors.contactInfo?.addresses[index]?.addressLine1.message}
                    InputProps={{
                        readOnly: Boolean(disabled),
                        "aria-readonly": Boolean(disabled),
                        disabled: Boolean(disabled),
                        'data-testid': 'address-line1-input'
                    }}
                />}
            />

            <Controller
                name={`contactInfo.addresses.${index}.addressLine2`}
                control={control}
                render={({ field }) => <TextField
                    {...field}
                    size="small"
                    className={clsx(
                        "addressline2",
                        classes?.addressLine2,
                        classes?.flexInput
                    )}
                    label="Address Line 2"
                    variant="outlined"
                    onBlur={formatTextField}
                    error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.addressLine2}
                    helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.addressLine2 && errors.contactInfo?.addresses[index]?.addressLine2.message}
                    InputProps={{
                        readOnly: Boolean(disabled),
                        "aria-readonly": Boolean(disabled),
                        disabled: Boolean(disabled),
                    }}
                />}
            />

            <Controller
                name={`contactInfo.addresses.${index}.countryID`}
                control={control}
                render={({ field }) => {
                    return (
                        <TextField
                            {...field}
                            className={clsx(
                                "country",
                                classes?.country,
                                classes?.flexInput
                            )}
                            label='Country'
                            variant='outlined'
                            value={field.value === undefined ? setValue(`contactInfo.addresses.${index}.countryID`, '') : field.value}
                            error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.countryID}
                            helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.countryID && errors.contactInfo?.addresses[index]?.countryID.message}
                            InputProps={{
                                readOnly: Boolean(disabled),
                                "aria-readonly": Boolean(disabled),
                                disabled: Boolean(disabled),
                            }}
                            size='small'
                            select
                            SelectProps={{
                                SelectDisplayProps: { "data-testid": "country-select" },
                            }}
                            data-testid='country-textfield'
                            FormHelperTextProps={{
                                'data-testid': 'country-helpertext'
                            }}
                        >
                            {countries && countries.length > 0 && countries.map((country) => {
                                const isSelected = country.countryID === field.value;
                                return (
                                    <MenuItem
                                        className={clsx(`${countries.countryName}`, [classes?.selectedListItem, "scope", (isSelected ? "selected" : "")])}
                                        key={country.countryID}
                                        value={country.countryID}
                                    >
                                        {country.countryName}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    )
                }}
            />

            <Controller
                name={`contactInfo.addresses.${index}.city`}
                control={control}
                render={({ field }) => <TextField
                    {...field}
                    size="small"
                    className={clsx("city", classes?.city, classes?.flexInput)}
                    label="City"
                    variant="outlined"
                    onBlur={formatTextField}
                    error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.city}
                    helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.city && errors.contactInfo?.addresses[index]?.city.message}
                    InputProps={{
                        readOnly: Boolean(disabled),
                        "aria-readonly": Boolean(disabled),
                        disabled: Boolean(disabled),
                    }}
                />}
            />

            <Controller
                name={`contactInfo.addresses.${index}.subdivisionID`}
                control={control}
                render={({ field }) => <TextField
                    {...field}
                    id='subdivision-textfield'
                    size="small"
                    data-id="subdivision"
                    data-testid='subdivision-textfield'
                    className={clsx("subdivion", classes?.stateDropdown, classes?.flexInput)}
                    label={subdivisionLabel}
                    native="true"
                    variant="outlined"
                    value={field.value === undefined ? setValue(`contactInfo.addresses.${index}.subdivisionID`, '') : field.value}
                    error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.subdivisionID}
                    helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.subdivisionID && errors.contactInfo?.addresses[index]?.subdivisionID.message}
                    select
                    SelectProps={{
                        SelectDisplayProps: { "data-testid": "subdivision-select" },
                    }}
                    InputProps={{
                        readOnly: Boolean(disabled),
                        "aria-readonly": Boolean(disabled),
                        disabled: Boolean(disabled),
                    }}
                    FormHelperTextProps={{
                        'data-testid': 'subdivision-helpertext'
                    }}
                >
                    {countryID !== '' && subdivisions && subdivisions.length > 0 && subdivisions.map((subdivision) => {
                        const isSelected = subdivision.subdivisionID === field.value;
                        return (
                            <MenuItem
                                className={clsx(`${subdivision.subdivisionName}`, [classes?.selectedListItem, "scope", (isSelected ? "selected" : "")])}
                                key={subdivision.subdivisionID}
                                value={subdivision.subdivisionID}
                            >
                                {subdivision.subdivisionName}
                            </MenuItem>
                        );
                    })}
                    {countryID === '' &&
                        <MenuItem className={clsx(`select-a-country`)} value=''>
                            Select a country
                        </MenuItem>
                    }
                </TextField>}
            />

            <Controller
                name={`contactInfo.addresses.${index}.postalCode`}
                control={control}
                render={({ field }) => <TextField
                    {...field}
                    size="small"
                    className={clsx("postalcode", classes?.postalCode, classes?.flexInput)}
                    label="Postal Code"
                    variant="outlined"
                    onBlur={formatTextField}
                    error={!!errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.postalCode}
                    helperText={errors.contactInfo?.addresses && errors.contactInfo?.addresses[index]?.postalCode && errors.contactInfo?.addresses[index]?.postalCode.message}
                    InputProps={{
                        readOnly: Boolean(disabled),
                        "aria-readonly": Boolean(disabled),
                        disabled: Boolean(disabled),
                    }}
                />}
            />
        </>
    );
};

AddressSubform.defaultProps = {
    disabled: false,
};

AddressSubform.propTypes = {
    index: PropTypes.number,
    disabled: PropTypes.bool,
};

export default AddressSubform;
